<template>
  <div class="loginBox">
    <a-row class="login">
      <a-col class="left" :xl="14" :lg="14">
        <img class="leftPic" src="@/assets/images/login/loginPic.png" alt="" />
      </a-col>
      <a-col class="right" :xl="10" :lg="10">
        <div class="rightFix">
          <a-select ref="select" v-model:value="selectValue" style="width: 164px" :placeholder="t('languMsg')" @change="handleChange" size="large">
            <a-select-option value="zh_CN">Chinese 中文</a-select-option>
            <a-select-option value="en_US">English 英语</a-select-option>
          </a-select>
        </div>
        <div class="contBox">
          <div class="navBox">
            <img class="logoImg" src="@/assets/images/amazon_and_wahool.png" alt="" />
          </div>
          <div class="authorized-title">{{ t('login.amazonLoginTitle') }}</div>
          <div class="user-box">
            <p class="logged-in-user-title">{{ t('login.logged') }}</p>
            <p class="user-border">
              <img src="@/assets/images/profile-circle.png" alt="user_icon" />
              <span>{{ `${userInfo?.account}@${userInfo?.businessBo?.name}` }}</span>
            </p>
          </div>
          <div class="log-in-other-accounts" @click="handleLoginOther">{{ t('login.logInOther') }}</div>
          <div class="formBox">
            <a-button class="subBtn" :loading="loading" type="primary" block @click="handleConfirmAuthorize">
              {{ t('login.ConfirmBinding') }}
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal v-model:visible="visible" :title="t('login.selectStoreTitle')" :footer="() => null">
      <div class="modal-select-list">
        <a-radio-group v-model:value="checkboxValue" @change="checkboxOnChange" style="width: 100%">
          <div class="modal-select-li" :span="8" v-for="item in storeInfoRef.stores" :key="item.idCode">
            <!-- 已授权的：disabled   authonActive-->
            <a-radio :style="radioStyle" :value="item.idCode">{{ item.name }}</a-radio>
            <span>{{ item.authorizationStateDesc ? `( ${item.authorizationStateDesc} )` : '' }}</span>
          </div>
        </a-radio-group>
      </div>
      <!-- <p class="modal-select-num">{{ t('login.selectedText') }}: {{ checkboxValue.length }} {{ t('login.selectedNum') }}</p> -->
      <div class="modal-footer">
        <div class="modal-cancel-btn" @click="handleCancel">{{ t('login.modalCancel') }}</div>
        <div class="modal-confirm-btn" :class="{ 'disable-btn': !checkboxValue.length }" @click="handleConfirm">{{ t('login.modalConfirm') }}</div>
      </div>
    </a-modal>
    <a-modal v-model:visible="errVisible" :closable="false" :footer="null">
      <div class="contentBox">
        <div class="title">{{ t('Amazon.authorizationFailedErr') }}！</div>
        <div class="desc">
          {{ t('Amazon.authorizationBinding') }}
          <span>{{ storeInfoRef.othersAuthorizedBusinessName }} - {{ storeInfoRef.othersAuthorizedStoreName }}</span>
          ，{{ t('Amazon.authorizationRetry') }}
        </div>
        <div class="btn" @click="closeErr">{{ t('Amazon.closeBtn') }}</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { ref, defineComponent, onMounted, computed, watch, reactive } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { storesForAmazonList } from '@/api/live.js'
  export default defineComponent({
    name: 'BindAmazon',
    setup() {
      const { t, locale } = useI18n()
      const rules = ref()
      const selectValue = ref(null)
      const router = useRouter()
      const store = useStore()
      let loading = ref(false)
      const errVisible = ref(false) //授权失败的时候展示
      const sellingPartnerId = ref('')
      const radioStyle = reactive({
        display: 'flex'
      })
      const userInfo = computed(() => store.getters.userInfo)
      //判断语言并赋值
      const payload = localStorage.getItem('languageSet') || 'zh_CN'
      if (payload) {
        selectValue.value = payload
      }
      // 选择店铺弹窗
      const visible = ref(false)
      // 自己的店铺列表
      const storeInfoRef = ref({})
      // 多选框选中的值
      const checkboxValue = ref(JSON.parse(sessionStorage.getItem('storeIdCodes')) || '')
      //根据语言变化重新赋值rules
      const rulesFun = () => {
        rules.value = {
          name: {
            required: true,
            message: t('login.userNamePlas'),
            trigger: 'blur'
          },
          password: {
            required: true,
            message: t('login.passwordPla'),
            trigger: 'blur'
          }
        }
      }
      rulesFun()

      //切换语言
      const handleChange = el => {
        console.log(el, 'el-el')
        locale.value = el
        localStorage.setItem('languageSet', el)
        rulesFun()
      }
      //监听语言切换，提示信息语言实时响应切换
      watch(locale, () => {
        rulesFun()
      })

      onMounted(async () => {
        //获取selling_partner_id 获取URL查询部分（即问号后面的部分）
        const payload = getUrlParams(location.href)
        // 请求商店列表
        const result = await storesForAmazonList({ sellingPartnerId: payload.selling_partner_id })
        console.log(result, 'result---result')
        if (result.code === 200) {
          if (result.data.sellerCentralOthersAuthorized) {
            errVisible.value = true
          } else {
            visible.value = true
          }
          storeInfoRef.value = result.data
        }
      })
      const getUrlParams = url => {
        // 通过 ? 分割获取后面的参数字符串
        let urlStr = url.split('?')[1]
        // 创建空对象存储参数
        let obj = {}
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
          // 再通过 = 将每一个参数分割为 key:value 的形式
          let arr = paramsArr[i].split('=')
          obj[arr[0]] = arr[1]
        }
        return obj
      }
      /**
       * 点击确认授权
       */
      const handleConfirmAuthorize = () => {
        // 跳转亚马逊店铺去授权
        // location.href = `${sessionStorage.getItem('amazon_callback_uri')}?redirect_uri=https://${window.location.hostname}/integrated/Amazon&amazon_state=${sessionStorage.getItem(
        //   'amazon_state'
        // )}&state=${userInfo.value?.idCode}`
        visible.value = true
      }
      /**
       * 点击登录其他账户
       */
      const handleLoginOther = () => {
        const payload = getUrlParams(location.href)
        window.localStorage.removeItem('userType')
        window.localStorage.removeItem('userInfo')
        sessionStorage.removeItem('storeIdCodes')
        store.commit('setToken', '')
        store.commit('setUserName', '')
        store.commit('setMyResource', [])
        store.commit('setUserType', '')
        router.push({
          path: '/amazonWahoolLogin',
          query: {
            sellingPartnerIds: payload.selling_partner_id
          }
        })
      }
      /**
       * 多选框选中事件
       */
      const checkboxOnChange = val => {
        console.log(val, 'val------val')
        sessionStorage.setItem('storeIdCodes', JSON.stringify(val.target.value))
      }
      /**
       * 点击弹窗确定跳转亚马逊
       */
      const handleConfirm = () => {
        if (checkboxValue.value) {
          // 跳转亚马逊店铺去授权
          location.href = `${sessionStorage.getItem('amazon_callback_uri')}?redirect_uri=https://${
            window.location.hostname
          }/integrated/Settings&amazon_state=${sessionStorage.getItem('amazon_state')}&state=${userInfo.value?.idCode}`
        }
      }
      /**
       * 点击弹窗取消
       */
      const handleCancel = () => {
        visible.value = false
      }
      //点击关闭失败的弹框
      const closeErr = () => {
        errVisible.value = false
      }
      return {
        t,
        selectValue,
        rules,
        loading,
        handleChange,
        userInfo,
        handleConfirmAuthorize,
        handleLoginOther,
        visible,
        checkboxValue,
        checkboxOnChange,
        handleConfirm,
        handleCancel,
        radioStyle,
        storeInfoRef,
        errVisible,
        closeErr
      }
    }
  })
</script>
<style scoped lang="less">
  ::v-deep(.ant-radio-wrapper) {
    display: flex;
    align-items: center;
  }
  ::v-deep(.ant-radio-group) {
    width: 100%;
  }
  ::v-deep(.ant-radio) {
    width: 16px;
    height: 16px;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .contentBox {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    .title {
      font-size: 20px;
      color: #fb5d43;
      font-weight: 600;
      text-align: center;
    }
    .desc {
      font-size: 15px;
      color: #797979;
      font-weight: 400;
      margin: 52px 0;
      text-align: center;
      span {
        font-size: 15px;
        color: #ff6700;
        font-weight: 400;
      }
    }
    .btn {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #000;
      text-align: center;
      background: #fff;
      font-size: 15px;
      line-height: 40px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .authonActive {
    color: rgba(0, 0, 0, 0.25);
  }
  .loginBox {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .login {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .left {
        width: 58%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #faf9fe;
        .leftPic {
          width: 500px;
          height: 500px;
          display: flex;
        }
      }
    }
    .right {
      width: 42%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      position: relative;
    }
  }
  .contBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .navBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 36px;
      .logoImg {
        width: 329px;
        height: 48px;
        margin-right: 12px;
      }
      span {
        font-size: 20px;
        line-height: 48px;
        color: #131011;
      }
    }
    .formBox {
      width: 330px;
      .lg-form-input {
        width: 100%;
        height: 40px;
        padding: 0px 10px;
        border-radius: 8px;
        border: 1px solid #d2d2d2;
        overflow: hidden;
        box-sizing: border-box;
        font-size: 14px;
        margin: 0;
      }
    }
  }
  .subBtn {
    width: 330px;
    height: 40px;
    background: #131011;
    border-radius: 8px;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
  }
  .rightFix {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  #login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    padding: 0 10px;
  }
  .authorized-title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 42px;
  }
  .logged-in-user-title {
    color: var(--grayscale-900, #131011);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
  .user-box {
    width: 100%;
  }
  .user-border {
    width: 329px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d2d2d2;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    color: var(--grayscale-900, #131011);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
    margin-top: 8px;
    img {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 12px;
    }
  }
  .log-in-other-accounts {
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    margin: 22px 0 30px;
    cursor: pointer;
  }
  :global(.ant-modal) {
    top: 220px !important;
    width: 478px !important;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    text-align: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  :global(.ant-modal-content) {
    padding: 0 32px;
  }
  .modal-select-num {
    text-align: center;
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    margin: 25px 0;
  }
  .modal-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .modal-cancel-btn {
      display: flex;
      width: 133px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid #000;
      background: #fff;
      cursor: pointer;
    }
    .modal-confirm-btn {
      display: flex;
      width: 133px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(--grayscale-900, #131011);
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
    .disable-btn {
      background: #ccc !important;
    }
  }
  .modal-select-list {
    overflow-y: scroll;
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  .modal-select-li {
    display: flex;
    width: 95%;
    min-height: 45px;
    // justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: -2px 0px 10px 0px rgba(170, 170, 170, 0.3);
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    margin: 17px auto;
    padding: 0 10px;
  }
  :global(.ant-checkbox-group) {
    width: 100%;
  }
  .wahool-icon {
    width: 22px;
    height: 22px;
  }
</style>
<style>
  .ant-form-item-explain-error {
    padding-left: 15px !important;
    margin-top: 5px !important;
  }
</style>
